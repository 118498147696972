import "./sibling-navigation-submenu.scss";

import { SiblingNavigationSubmenuItem } from "./sibling-navigation-submenu-item";
import { SiblingNavigationItemProps } from "./sibling-navigation-item";

export interface SiblingNavigationSubmenuProps {
  submenu: Array<SiblingNavigationItemProps>;
}

export function SiblingNavigationSubmenu({
  submenu,
}: SiblingNavigationSubmenuProps) {
  return (
    <ul className="hbs-sibling-navigation-submenu-list">
      {submenu.map((subitem, i) => (
        <SiblingNavigationSubmenuItem {...subitem} key={i} />
      ))}
    </ul>
  );
}
