import cn from "clsx";
import { SiblingNavigationItemProps } from "./sibling-navigation-item";
import { Link } from "design-system/components/primitives/link/link";

export function SiblingNavigationSubmenuItem({
  title,
  link,
  isCurrent,
}: SiblingNavigationItemProps) {
  const listSubitemClass = cn(
    "hbs-sibling-navigation-list-item",
    isCurrent && "hbs-sibling-navigation-list-item--current",
  );

  return (
    <li className={listSubitemClass}>
      {isCurrent ? (
        <span
          className="hbs-sibling-navigation-list-item__text"
          aria-current="true"
        >
          {title}
        </span>
      ) : (
        <Link className="hbs-sibling-navigation-list-item__link" href={link}>
          <span className="hbs-sibling-navigation-list-item__text">
            {title}
          </span>
        </Link>
      )}
    </li>
  );
}
