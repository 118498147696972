import {
  DetailPage,
  DetailPageTopper,
} from "design-system/components/pages/detail-page/detail-page";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { DetailPageEntry } from "frontend/contentful/schema/pages";
import { createSiteHeaderProps } from "../universal/site-header.props";
import { createSiteFooterProps } from "../universal/site-footer.props";
import { RenderContentfulEntry } from "../utils/render-contentful-entry";
import { Aside } from "design-system/components/primitives/aside/aside";
import { ContentfulHead } from "../primitives/head.contentful";
import { SiblingNavigation } from "design-system/components/primitives/sibling-navigation/sibling-navigation";
import { usePageNavigation } from "frontend/hooks/use-page-navigation";
import { createLazyTopperProps } from "../toppers/lazy-topper.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { Footnotes } from "design-system/components/primitives/footnote/footnotes";
import { usePageFootnotes } from "frontend/hooks/use-page-footnotes";
import { usePageProps } from "frontend/hooks/use-page-props";
import { ContentfulPageSections } from "./page-sections.contentful";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const ContentfulDetailPage = createContentfulComponent<DetailPageEntry>(
  ({ entry }) => {
    const { siteDetailPageSettings } = usePageProps();
    const { site, getEntry } = useContentful();
    const { navigation, breadcrumbs } = usePageNavigation();
    const footnotes = usePageFootnotes(entry.fields.blocks);
    const hasRightRail = entry.fields.rightRail;

    return (
      <>
        <ContentfulHead entry={entry.fields.seo} />

        <DetailPage
          theme={getEntry(siteDetailPageSettings)?.fields.colorThemeOverride}
          breadcrumbs={breadcrumbs}
          topper={
            createLazyTopperProps(entry.fields.topper) as DetailPageTopper
          }
          header={{ name: "SiteHeader", props: createSiteHeaderProps(site) }}
          footer={createSiteFooterProps(site?.fields.footer)}
          body={{
            children: (
              <>
                {entry.fields.blocks?.map((block, i) => (
                  <RenderContentfulEntry key={i} entry={block} />
                ))}
                {<Footnotes footnotes={footnotes} />}
              </>
            ),
            leftRail:
              navigation.menu.length > 0 ? (
                <Aside>
                  <SiblingNavigation {...navigation} />
                </Aside>
              ) : null,
            preFooterContent: (
              <ContentfulPageSections
                entry={entry}
                options={{ key: "preFooterContent" }}
              />
            ),
            rightRail: hasRightRail ? (
              <Aside>
                <RenderContentfulRichText document={entry.fields.rightRail} />
              </Aside>
            ) : null,
          }}
        />
      </>
    );
  },
);
