import "./sibling-navigation-item.scss";

import cn from "clsx";
import { SiblingNavigationSubmenu } from "./sibling-navigation-submenu";
import { Link } from "design-system/components/primitives/link/link";

export interface SiblingNavigationItemProps {
  title: string;
  link: string;
  submenu?: Array<SiblingNavigationItemProps>;
  isCurrent?: boolean;
}

export function SiblingNavigationItem({
  isCurrent,
  link,
  submenu,
  title,
}: SiblingNavigationItemProps) {
  // Set current item styles
  const listItemClass = cn(
    "hbs-sibling-navigation-list-item",
    isCurrent && "hbs-sibling-navigation-list-item--current",
  );

  const submenuHasCurrentItem = submenu?.some((item) => item.isCurrent);

  return (
    <li className={listItemClass}>
      {isCurrent ? (
        <span className="hbs-sibling-navigation-list-item__link">
          <span
            className="hbs-sibling-navigation-list-item__text"
            aria-current="true"
          >
            {title}
          </span>
          {submenu && submenu.length > 0 && (
            <SiblingNavigationSubmenu submenu={submenu} />
          )}
        </span>
      ) : (
        <>
          <Link className="hbs-sibling-navigation-list-item__link" href={link}>
            <span className="hbs-sibling-navigation-list-item__text">
              {title}
            </span>
          </Link>
          {submenu && submenuHasCurrentItem && (
            <SiblingNavigationSubmenu submenu={submenu} />
          )}
        </>
      )}
    </li>
  );
}
