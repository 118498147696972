import { EditAttributes } from "design-system/types/types";
import "./aside.scss";
import cn from "clsx";
import { ReactNode } from "react";

export interface AsideProps {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  editAttributes?: {
    title?: EditAttributes;
  };
}

/**
 * For content that goes in the left or right rail of pages
 *
 * ## See it in use on...
 * - An [archive page](/story/example-pages-archive-pages-story-archive--story)
 * - An [article page](/story/example-pages-article-pages-big-art-topper--story)
 * - A [detail page](/story/example-pages-detail-pages-advancing-racial-equity-diversity-action--story)
 *
 * - **`id: P-005-000-00`**
 */

export function Aside({
  title,
  children,
  editAttributes,
  className,
}: AsideProps) {
  if (!title && !children) {
    return null;
  }

  const asideContentClass = cn("hbs-aside__content", className && className);

  return (
    <div className="hbs-aside">
      {title && (
        <h2 {...editAttributes?.title} className="hbs-aside__title">
          {title}
        </h2>
      )}
      {children && <div className={asideContentClass}>{children}</div>}
    </div>
  );
}
