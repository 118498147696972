import "./sibling-navigation.scss";

import { ChangeEvent, Fragment } from "react";

import {
  SiblingNavigationItem,
  SiblingNavigationItemProps,
} from "./sibling-navigation-item";
import { useBreakpoint } from "design-system/hooks/use-breakpoint";
import { Link } from "../link/link";

export interface SiblingNavigationHeading {
  text: string;
  link?: string;
}

export interface SiblingNavigationProps {
  heading?: SiblingNavigationHeading;
  menu: Array<SiblingNavigationItemProps>;
}

/**
 * Sticky page navigation intended to be used the left rail of detail pages
 *
 * ## See it in use on...
 * - A [detail page](/story/example-pages-detail-pages-advancing-racial-equity-diversity-action--story)
 *
 * - **`id: P-022-000-00`**
 */
export function SiblingNavigation({ menu, heading }: SiblingNavigationProps) {
  const { isDesktop } = useBreakpoint();

  const onDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    window.location.assign(e.target.value);
  };

  if (!menu || menu.length < 1) {
    return null;
  }

  let navCount = menu.length;
  menu.forEach((item) => {
    const submenu = item.submenu;
    const submenuCurrent = submenu?.some(
      (item: SiblingNavigationItemProps) => item.isCurrent,
    );
    submenu && submenuCurrent && (navCount += submenu.length);
  });
  const skipNav = navCount > 10 ? true : false;

  return (
    <nav aria-label="Sibling" className="hbs-sibling-navigation">
      {skipNav && (
        <Link
          href="#skipto-body"
          className="hbs-global-visually-hidden skiptoleftnav noindex"
          target="_self"
        >
          Skip Left Navigation Links
        </Link>
      )}

      {heading && (
        <div className="hbs-sibling-navigation__title" aria-hidden={!isDesktop}>
          {heading.link ? (
            <Link href={heading.link}>{heading.text}</Link>
          ) : (
            heading.text
          )}
        </div>
      )}

      {/* List on desktop */}
      <ul className="hbs-sibling-navigation__list" aria-hidden={!isDesktop}>
        {menu.map((item, i) => (
          <SiblingNavigationItem {...item} key={i} />
        ))}
      </ul>

      {/* Select dropdown on mobile */}
      <div className="hbs-sibling-navigation__select-wrapper">
        <form>
          {heading && (
            <label
              htmlFor="sibling-navigation"
              className="hbs-sibling-navigation__title--mobile"
            >
              {heading.link ? (
                <Link href={heading.link}>{heading.text}</Link>
              ) : (
                heading.text
              )}
            </label>
          )}
          <select
            aria-hidden={isDesktop}
            className="hbs-sibling-navigation__select"
            id="sibling-navigation"
            name="Sibling Navigation"
            defaultValue="#"
            onChange={onDropdownChange}
          >
            <option value="#" disabled>
              Navigate to...
            </option>
            {menu.map((item, i) => (
              <Fragment key={i}>
                <option value={item.link}>{item.title}</option>
                {item.submenu &&
                  item.submenu.length > 0 &&
                  item.submenu.map((subitem, j) => (
                    <option key={j} value={subitem.link}>
                      &nbsp;&nbsp;&nbsp;&nbsp;{subitem.title}
                    </option>
                  ))}
              </Fragment>
            ))}
          </select>
        </form>
      </div>
    </nav>
  );
}
